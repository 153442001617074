@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Montserrat', sans-serif;
    user-select: none;
    /*transition: all 0.1s ease-in-out;*/
}

/*@keyframes rainbow {*/
/*    0%, 100% {*/
/*        background-position: 0% 50%;*/
/*    }*/
/*    50% {*/
/*        background-position: 100% 50%;*/
/*    }*/
/*}*/

/*.bubble {*/
/*    animation: rainbow 3s ease infinite;*/
/*    background: linear-gradient(270deg, #f3ec78, #af4261, #28b4e5, #b3ffab);*/
/*    background-size: 800% 800%;*/
/*}*/

/*@keyframes rainbow {*/
/*    0%, 100% {*/
/*        background-position: 0% 50%;*/
/*    }*/
/*    50% {*/
/*        background-position: 100% 50%;*/
/*    }*/
/*}*/

/*.bubble {*/
/*    animation: rainbow 8s ease infinite; !* Lassabb animáció *!*/
/*    background: linear-gradient(270deg, #f3ec78, #af4261, #28b4e5, #b3ffab, #ff8c00, #9400d3);*/
/*    background-size: 1200% 1200%;*/
/*    opacity: 0.7; !* Enyhén áttetsző *!*/
/*    padding: 20px; !* Több tér a szövegnek *!*/
/*    border-radius: 20px; !* Nagyobb kerekítés *!*/
/*    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25); !* Lágyabb árnyék a jobb kiemelésért *!*/
/*    color: black; !* Fehér szöveg minden buborékon belül *!*/
/*    margin-bottom: 20px; !* Több tér a buborékok között *!*/
/*}*/

/*@keyframes rainbow {*/
/*    0%, 100% {*/
/*        background-position: 0% 50%;*/
/*    }*/
/*    50% {*/
/*        background-position: 100% 50%;*/
/*    }*/
/*}*/

/*.bubble {*/
/*    animation: rainbow 5s ease infinite;*/
/*    background: linear-gradient(270deg, #f3ec78, #af4261, #28b4e5, #b3ffab, #ff8c00, #9400d3);*/
/*    background-size: 1200% 1200%;*/
/*    padding: 20px;*/
/*    border-radius: 20px;*/
/*    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);*/
/*    color: black;*/
/*    margin-bottom: 20px;*/
/*}*/

@keyframes rainbow {
    0%, 100% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
}

.bubble {
    animation: rainbow 20s ease infinite;
    background: linear-gradient(270deg, #a5f2e9, #7ec8e3, #a5e3a1, #7ee3b8, #7edbe3, #a1e3a5);
    background-size: 1200% 1200%;
    /*padding: 20px;*/
    /*border-radius: 20px;*/
    /*box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);*/
    /*color: black; !* Darker text for better readability *!*/
    /*margin-bottom: 20px;*/
    /*overflow: hidden; !* Ensures the background only shows within the rounded corners *!*/
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;

    animation: spin 1s linear infinite;
}


